import * as React from "react"
import { FunctionComponent, useEffect, useState } from "react"
import Header from "../../components/header/Header"
import { graphql } from "gatsby"
import MainWrap from "../../components/main-wrap/MainWrap"
import MainTitle from "../../components/main-title/MainTitle"
import { withTranslation, WithTranslation } from "react-i18next"

import {
  Link,
  DirectLink,
  Element,
  Events,
  animateScroll,
  scrollSpy,
  scroller,
} from "react-scroll"
import {
  MenuWrap,
  MenuHolder,
  Alergies,
  HolderWrapMenu,
  SideNav,
  NavItem,
  FreeSouce,
  LeftSouce,
  RightSouce,
  Toppings,
} from "./Menu.css"
import MenuItem from "../../components/menu-item/MenuItem"
import { Title, Subtitle } from "../../components/menu-item/MenuItem.css"
import AlertIcon from "../../assets/images/alert.png"
import NavTabs from "../../components/nav-tabs/NavTabs"
import { Lang, PageContext } from "../../constants"
export type Props = {
  data?: any
  pageContext: PageContext
}
export type Functions = {}
export type AllProps = Props & Functions & WithTranslation
const Menu: FunctionComponent<AllProps> = ({ data, pageContext, t }) => {
  const nodes = data.allMarkdownRemark.nodes
  const [activeMenu, setActiveMenu] = useState(0)
  const [colspan, setColspan] = useState(false)

  const getValue = (param, object = null) => {
    const query = object || data.allMarkdownRemark.nodes[0].frontmatter
    try {
      return pageContext.lang === Lang.LV ? query[param] : query[`${param}_en`] || ""
    } catch (e) {
      console.warn(e)
      return ""
    }
  }


  useEffect(() => {
    Events.scrollEvent.register("begin", function () {
      console.log("begin", arguments)
    })

    Events.scrollEvent.register("end", function () {
      console.log("end", arguments)
    })
    scrollSpy.update()

    console.log({ scroller })

    return () => {
      Events.scrollEvent.remove("begin")
      Events.scrollEvent.remove("end")
    }
  }, [])

  const scrollToItem = (id: any) => {
    scroller.scrollTo(`menu-${id}`, {
      duration: 400,
      delay: 0,
      smooth: "easeOut",
      offset: -60,
    })
  }

  useEffect(() => {
    const scrollEvent = () => {
      const foundReduced = nodes.reduce((acc, id, index) => {
        if (isElementInViewport(document.getElementById(`menu-${index}`))) {
          return (acc = [...acc, index])
        }
        return acc
      }, [])
      if (foundReduced.length) {
        setActiveMenu(Number(foundReduced[foundReduced.length - 1]))
      }
    }

    window.addEventListener("scroll", scrollEvent)

    const handleResize = () => {
      setColspan(window.innerWidth < 750)
    }

    window.addEventListener("resize", handleResize)

    return () => {
      window.removeEventListener("scroll", scrollEvent)
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  const isElementInViewport = el => {
    try {
      var rect = el.getBoundingClientRect()
      const isInViewPort =
        rect.bottom > 150 &&
        rect.top + 100 <
          (window.innerHeight || document.documentElement.clientHeight)
      return isInViewPort
    } catch (e) {
      return false
    }
  }

  return (
    <MainWrap>
      <Header
        lang={pageContext.lang}
        title={"Delisnack ēdienkarte"}
        renderNavTabs={() => (
          <NavTabs
            activeId={activeMenu}
            onChange={id => scrollToItem(id)}
            tabs={nodes.map(({ frontmatter }, index) => ({
              title: getValue("title", frontmatter),
              id: index,
            }))}
          />
        )}
        renderSideNav={() => (
          <SideNav>
            {nodes.map(({ frontmatter }, index) => (
              <NavItem
                active={index === activeMenu}
                key={index}
                onClick={() => {
                  console.log("Scroll top")
                  scrollToItem(index)
                }}
              >
                {getValue("title", frontmatter)}
              </NavItem>
            ))}
          </SideNav>
        )}
      />

      <HolderWrapMenu>
        {nodes.map(({ frontmatter }, index) => {
          const toppings =
            pageContext.lang === Lang.LV
              ? frontmatter.toppings
              : frontmatter.toppings_en

          return (
            <MenuWrap key={getValue("title", frontmatter)} id={`menu-${index}`}>
              <Element
                name={`menu-${index}`}
                key={getValue("title", frontmatter)}
              >
                <MainTitle responsiveTopNudge={index === 0 ? 100 : 60}>
                  {getValue("title", frontmatter)}
                </MainTitle>
                {frontmatter.menu && frontmatter.menu.length && (
                  <MenuHolder>
                    {frontmatter?.menu.map((menuItem, index) => {
                      return (
                        <MenuItem
                          images="True"
                          colspan={colspan}
                          lang={pageContext.lang}
                          key={index}
                          item={menuItem}
                        />
                      )
                    })}
                  </MenuHolder>
                )}
                {Boolean(toppings) && (
                  <Toppings>
                    <Title>{t("toppings")}</Title>
                    <Subtitle>{toppings.toString()}</Subtitle>
                  </Toppings>
                )}

                {frontmatter.freeSouce && (
                  <FreeSouce>
                    <LeftSouce>
                      <img src={AlertIcon} />
                      <p>
                        <span
                          dangerouslySetInnerHTML={{ __html: t("freeSouce") }}
                        />
                      </p>
                    </LeftSouce>
                    <RightSouce
                      dangerouslySetInnerHTML={{ __html: t("freeSouce2") }}
                    />
                  </FreeSouce>
                )}
                {Boolean(getValue("alergyList", frontmatter).length > 5) && (
                  <Alergies>
                    *{t("alergens")}:{getValue("alergyList", frontmatter)}
                  </Alergies>
                )}
              </Element>
            </MenuWrap>
          )
        })}
      </HolderWrapMenu>
    </MainWrap>
  )
}

//<Element name={`menu-${index}`} key={frontmatter.title}>

export const query = graphql`
  {
    allMarkdownRemark(
      filter: { fields: { collection: { eq: "foodGroup" } } }
      sort: { fields: frontmatter___orderId, order: ASC }
    ) {
      nodes {
        frontmatter {
          freeSouce
          orderId
          title
          title_en
          toppings
          toppings_en
          alergyList
          menu {
            title
            title_en
            subtitle
            subtitle_en
            price
            image
            alergies
            extras {
              price
              title
              title_en
            }
            sizes {
              price
              title
              title_en
            }
            badge {
              type
            }
          }
        }
      }
    }
  }
`
export default withTranslation()(Menu)
