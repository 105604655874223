import * as React from "react"
import { FunctionComponent } from "react"
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles"
import AppBar from "@material-ui/core/AppBar"
import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"
import { Wrap } from "./NavTabs.css"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appbarRoot: {
      //background: "#000002",
      top:'55px',
      zIndex:1,
      backgound:'yellow',
      [theme.breakpoints.up("md")]: {
        display: "none",
      },
      [theme.breakpoints.up("sm")]: {
        top:'64px',
      },

    },
    menuButton: {
      marginRight: theme.spacing(2),
      display: "flex",
      [theme.breakpoints.up("md")]: {
        display: "none",
      },
    },


  })
)

export type Props = {
  tabs: { title: string; id: string }[]
  activeId: any
}
export type Functions = {
  onChange(id: any)
}
export type AllProps = Props & Functions
export const NavTabs: FunctionComponent<AllProps> = ({ tabs, activeId, onChange }) => {
  const classes = useStyles()
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    onChange(newValue)
  }
  return (
    <Wrap>
      <AppBar position="fixed" color="default" classes={{ root: classes.appbarRoot }}>
        <Tabs
          scrollButtons="off"
          value={activeId}
          onChange={handleChange}
          variant="scrollable"
          indicatorColor="primary"
          textColor="primary"
          aria-label="scrollable force tabs example"
        >
          {tabs.map((tab, index) => (
            <Tab
              key={index}
              label={tab.title}
              id={`scrollable-force-tab-${index}`}
              aria-controls={`scrollable-force-tabpanel-${index}`}
            />
          ))}
        </Tabs>
      </AppBar>
    </Wrap>
  )
}

export default NavTabs
